import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.width ? props.width : "100%")};
`;
const StyledTooltip = styled.div`
  position: absolute;
  display: flex;
  z-index: 9999;
  top: ${(props) => `${props.position.top}px`};
  left: ${(props) => `${props.position.left}px`};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  animation-name: ${(props) => (props.isVisible ? "fadeIn" : "fadeOut")};
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
`;

export default class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      position: { top: 0, left: 0 },
    };
    this.targetRef = React.createRef();
    this.tooltipRef = React.createRef();
    this.timeout = null;
  }

  showTooltip = () => {
    if (!this.props.disabled) {
      this.clearTimeout();
      this.timeout = setTimeout(() => {
        this.setState({ isVisible: true });
      }, this.props.delay || 300);
    }
  };

  hideTooltip = () => {
    this.clearTimeout();
    if (this.tooltipRef.current) {
      this.tooltipRef.current.style.animationName = "fadeOut";
    }
    this.timeout = setTimeout(() => {
      this.setState({ isVisible: false });
    }, 300);
  };

  clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  componentDidMount() {
    if (this.targetRef.current) {
      this.targetRef.current.addEventListener("mouseenter", this.showTooltip);
      this.targetRef.current.addEventListener("click", this.toggleTooltip);
      this.targetRef.current.addEventListener("mouseleave", this.hideTooltip);
    }
    if (this.tooltipRef.current) {
      this.tooltipRef.current.addEventListener(
        "animationend",
        this.handleAnimationEnd
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.disabled && this.props.disabled && this.state.isVisible) {
      this.hideTooltip();
    }
    else if (!prevState.isVisible && this.state.isVisible) {
      // The tooltip just became visible. Now we set its position.
      this.setPosition();
    }
  }

  componentWillUnmount() {
    if (this.targetRef.current) {
      this.targetRef.current.removeEventListener(
        "mouseenter",
        this.showTooltip
      );
      this.targetRef.current.removeEventListener("click", this.toggleTooltip);
      this.targetRef.current.removeEventListener(
        "mouseleave",
        this.hideTooltip
      );
    }
    if (this.tooltipRef.current) {
      this.tooltipRef.current.removeEventListener(
        "animationend",
        this.handleAnimationEnd
      );
    }
    this.clearTimeout();
  }

  handleAnimationEnd = (event) => {
    if (event.animationName === "fadeOut") {
      this.setState({ isVisible: false });
    }
  };

  toggleTooltip = () => {
    this.clearTimeout();
    this.setState(
      (prevState) => ({ isVisible: !prevState.isVisible }),
      () => {
        if (this.state.isVisible) {
          this.setPosition();
        }
      }
    );
  };

  setPosition = () => {
    if (!this.targetRef.current || !this.tooltipRef.current) return;

    const targetRect = this.targetRef.current.getBoundingClientRect();
    const tooltipWidth = this.tooltipRef.current.offsetWidth;
    const tooltipHeight = this.tooltipRef.current.offsetHeight;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const topPosition = {
      top: targetRect.top - tooltipHeight + window.scrollY,
      left:
        targetRect.left +
        targetRect.width / 2 -
        tooltipWidth / 2 +
        window.scrollX,
    };

    const bottomPosition = {
      top: targetRect.bottom + window.scrollY,
      left:
        targetRect.left +
        targetRect.width / 2 -
        tooltipWidth / 2 +
        window.scrollX,
    };

    const leftPosition = {
      top:
        targetRect.top +
        targetRect.height / 2 -
        tooltipHeight / 2 +
        window.scrollY,
      left: targetRect.left - tooltipWidth + window.scrollX,
    };

    const rightPosition = {
      top:
        targetRect.top +
        targetRect.height / 2 -
        tooltipHeight / 2 +
        window.scrollY,
      left: targetRect.right + window.scrollX,
    };

    const isOffScreenTop = (position) => position.top < window.scrollY;
    const isOffScreenBottom = (position) =>
      position.top + tooltipHeight > viewportHeight + window.scrollY;
    const isOffScreenLeft = (position) => position.left < window.scrollX;
    const isOffScreenRight = (position) =>
      position.left + tooltipWidth > viewportWidth + window.scrollX;

    let finalPosition = {};

    switch (this.props.position) {
      case "top":
        if (!isOffScreenTop(topPosition)) finalPosition = topPosition;
        else finalPosition = bottomPosition; // Fallback to bottom
        break;
      case "bottom":
        if (!isOffScreenBottom(bottomPosition)) finalPosition = bottomPosition;
        else finalPosition = topPosition; // Fallback to top
        break;
      case "left":
        if (!isOffScreenLeft(leftPosition)) finalPosition = leftPosition;
        else finalPosition = rightPosition; // Fallback to right
        break;
      case "right":
        if (!isOffScreenRight(rightPosition)) finalPosition = rightPosition;
        else finalPosition = leftPosition; // Fallback to left
        break;
      default:
        finalPosition = bottomPosition; // Default to bottom
        break;
    }

    // Check once more if the finalPosition is off-screen (for scenarios when even the fallback is off-screen)
    if (isOffScreenRight(finalPosition)) finalPosition = leftPosition;
    if (isOffScreenLeft(finalPosition)) finalPosition = rightPosition;
    if (isOffScreenBottom(finalPosition)) finalPosition = topPosition;
    if (isOffScreenTop(finalPosition)) finalPosition = bottomPosition;

    this.setState({
      position: finalPosition,
    });
  };

  render() {
    return (
      <>
        <Wrapper
          ref={this.targetRef}
          style={this.props.style}
          width={this.props.width}
        >
          {this.props.children}
        </Wrapper>
        {(this.state.isVisible || !this.props.unmountHTMLWhenHide) &&
          ReactDOM.createPortal(
            <StyledTooltip
              ref={this.tooltipRef}
              isVisible={this.state.isVisible}
              position={this.state.position}
              onAnimationEnd={this.handleAnimationEnd}
            >
              {this.props.html}
            </StyledTooltip>,
            document.getElementById("reactEntry")
          )}
      </>
    );
  }
}

Tooltip.propTypes = {
  position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  // other propTypes...
};

Tooltip.defaultProps = {
  position: "bottom",
};

import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchPricingForPlanogram } from "@redux/api/endpoints/planogram";
import { hideModal, showModal } from "@redux/slices/modalSlice";
var initialState = {
    data: null,
    filters: {
        chartType: "bar",
    },
};
var priceAssortmentSlice = createSlice({
    name: "priceAssortment",
    initialState: initialState,
    reducers: {
        priceAssortmentFilterSelected: function (state, action) {
            state.filters[action.payload.filter] = action.payload.selectedOption;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(fetchPricingForPlanogram.fulfilled, function (state, action) {
            if (state.data) {
                state.data.prices = action.payload.data;
            }
            else {
                state.data = { prices: action.payload.data };
            }
        })
            .addMatcher(isAnyOf(showModal, hideModal), function () { return initialState; });
    },
});
export var priceAssortmentFilterSelected = priceAssortmentSlice.actions.priceAssortmentFilterSelected;
export default priceAssortmentSlice.reducer;
